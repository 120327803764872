import React from "react"
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Switch,
} from "antd"
import moment from "moment-timezone"
import { injectIntl } from "gatsby-plugin-intl"

import Template from "../../layouts/base"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import TimeSeriesChart from "../../components/timeSeriesChart"
import authFetch from "../../services/network"
import { FormControl, InputLabel } from "@material-ui/core"

import errorIcon from "../../assets/images/Icon-Form-Error.png"

const { Panel } = Collapse
const { RangePicker } = DatePicker
const { Option } = Select

const REQUEST_OPTIONS = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
}

const GRANULARITIES = [
  {
    value: "second",
    text: "1-seg",
  },
  {
    value: "minute",
    text: "1-min",
  },
  {
    value: "fifteen_minute",
    text: "15-min",
  },
  {
    value: "thirty_minute",
    text: "30-min",
  },
  {
    value: "hour",
    text: "1-hour",
  },
  {
    value: "day",
    text: "1-day",
  },
  {
    value: "week",
    text: "1-week",
  },
  {
    value: "month",
    text: "1-month",
  },
  {
    value: "quarter",
    text: "1-quarter",
  },
  {
    value: "year",
    text: "1-year",
  },
]

const OPERATIONS = [
  {
    value: "select",
    text: "RAW",
  },
  {
    value: "average",
    text: "AVG",
  },
  {
    value: "last",
    text: "LAST",
  },
  {
    value: "next",
    text: "NEXT",
  },
  {
    value: "snap",
    text: "SNAP",
  },
]

// Values in seconds
const REFRESH_INTERVALS = [
  {
    value: 1,
    text: "1-seg",
  },
  {
    value: 5,
    text: "5-seg",
  },
  {
    value: 10,
    text: "10-seg",
  },
  {
    value: 30,
    text: "30-seg",
  },
  {
    value: 60,
    text: "1-min",
  },
  {
    value: 300,
    text: "5-min",
  },
  {
    value: 600,
    text: "10-min",
  },
]

// Intervals in seconds
const INTERVALS = [
  {
    value: 60,
    text: "last-min",
  },
  {
    value: 120,
    text: "last-2-min",
  },
  {
    value: 300,
    text: "last-5-min",
  },
  {
    value: 600,
    text: "last-10-min",
  },
  {
    value: 900,
    text: "last-15-min",
  },
  {
    value: 1800,
    text: "last-30-min",
  },
  {
    value: 3600,
    text: "last-hour",
  },
  {
    value: 7200,
    text: "last-2-hour",
  },
  {
    value: 43200,
    text: "last-12-hour",
  },
  {
    value: 86400,
    text: "last-day-b",
  },
  {
    value: 604800,
    text: "last-week",
  },
  {
    value: 1209600,
    text: "last-2-week",
  },
  {
    value: 2628000,
    text: "last-month",
  },
  {
    value: 31540000,
    text: "last-year",
  },
  {
    value: 63070000,
    text: "last-2-year",
  },
]

const GET_TIMESERIES_RETRIES = 3

class VisualizationPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ingests: null,
      prefixes: [],
      dictionary: [],
      metrics: [],
      series: null,
      chartTitle: "",
      submitDisabled: true,
      dictionaryFieldsDisabled: true,
      collapseActiveKey: "1",
      operation: "",
      loading: false,
      realTimeEnabled: false,
      labelFocus: {
        prefixes: "empty",
        ingests: "empty",
        dictionaryFields: "empty",
        dictionaryFieldValue: "empty",
        metrics: "empty",
        operation: "empty",
        granularity: "empty",
        offset: "empty",
        date: "empty",
        interval: "empty",
      },
    }

    this._initMemberProps()
    this._bindFunctions()
  }

  componentDidMount() {
    const prefixesPromise = authFetch(
      `${process.env.GATSBY_CONF_API_URL}/prefixes`,
      REQUEST_OPTIONS
    )
      .then(response => this._onPrefixesRequestResponse(response))
      .catch(reason => {
        console.error(`[ERROR]: Retrieving prefixes: ${reason}`)
        message.error(
          this.props.intl.formatMessage({
            id: "page-visualization-get-prefixes-ko",
          })
        )
      })

    const ingestsPromise = authFetch(
      `${process.env.GATSBY_CONSUMER_API_URL}/data/entities`,
      REQUEST_OPTIONS
    )
      .then(response => this._onEntitiesRequestResponse(response))
      .catch(reason => {
        console.error(`[ERROR]: Retrieving entities: ${reason}`)
        message.error(
          this.props.intl.formatMessage({
            id: "page-visualization-get-entities-ko",
          })
        )
      })

    Promise.all([prefixesPromise, ingestsPromise])
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  _initMemberProps() {
    this.ingests = []
    this.prefixes = []
    this.dictionary = []
    this.metrics = []
    this.formSubmitObject = {}
    this.collapseDefaultActiveKey = "1"
    this.getTimeseriesRetries = GET_TIMESERIES_RETRIES
    this.intervalTimer = null
    this.period = REFRESH_INTERVALS[2].value * 1000
    this.counter = 0
    this.submitFormForRealtime = {}
    this.operationForRealtime = ""
    this.realTimeInterval = INTERVALS[2].value
  }

  _bindFunctions() {
    this._onSubmitForm = this._onSubmitForm.bind(this)
    this._onClickResetButton = this._onClickResetButton.bind(this)
    this._onSelectIngest = this._onSelectIngest.bind(this)
    this._onChangePrefix = this._onChangePrefix.bind(this)
    this._onSelectDictionaryField = this._onSelectDictionaryField.bind(this)
    this._onChangeDictionaryFieldValues = this._onChangeDictionaryFieldValues.bind(
      this
    )
    this._onChangeMetric = this._onChangeMetric.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._onDateRangeChange = this._onDateRangeChange.bind(this)
    this._onChangeOffset = this._onChangeOffset.bind(this)
    this._onSelectGranularity = this._onSelectGranularity.bind(this)
    this._onSelectOperation = this._onSelectOperation.bind(this)
    this._onPanelButtonClick = this._onPanelButtonClick.bind(this)
    this._onRealTimeSwitchChange = this._onRealTimeSwitchChange.bind(this)
    this._onRefreshPeriodChange = this._onRefreshPeriodChange.bind(this)
    this._renderOperationForm = this._renderOperationForm.bind(this)
    this._renderRangePicker = this._renderRangePicker.bind(this)
    this._onIntervalChange = this._onIntervalChange.bind(this)
    this._renderRealTimeFromTimeSelector = this._renderRealTimeFromTimeSelector.bind(
      this
    )
  }

  _onPrefixesRequestResponse(response) {
    if (!this._responseHasError(response.status)) {
      response.json().then(prefixes => {
        this.prefixes = prefixes
        this.setState({
          prefixes: prefixes.map(p => ({ value: p.id, text: p.desc })),
        })
      })
    }
  }

  _onEntitiesRequestResponse(response) {
    if (!this._responseHasError(response.status)) {
      response
        .json()
        .then(ingests => {
          this.ingests = []
          for (let index in ingests) {
            if (
              !this.ingests.find(
                e =>
                  e.entity === ingests[index].entity &&
                  e.type === ingests[index].type
              )
            ) {
              this.ingests.push(ingests[index])
            }
          }
          this.setState({
            ingests: this.ingests.map(({ entity, type }) => ({
              value: entity,
              text: `${entity} (${type})`,
            })),
          })
        })
        .catch(reason => {
          console.error(`[ERROR]: Retrieving entities: ${reason}`)
          message.error(
            this.props.intl.formatMessage({
              id: "page-visualization-get-entities-ko",
            })
          )
        })
    }
  }

  _responseHasError(status) {
    const hasError = status !== 200

    if (hasError) {
      console.error(`[ERROR]: Generic error: ${status}`)
      message.error(
        this.props.intl.formatMessage(
          { id: "generic-error" },
          { status: status }
        )
      )
    }

    return hasError
  }

  _getDatesIntervalForRealTime() {
    return {
      start_date: moment()
        .subtract(this.realTimeInterval, "seconds")
        .toISOString(),
      end_date: moment().toISOString(),
    }
  }

  _loadTimeSeries() {
    this.setState({ loading: true })
    const body = this.state.realTimeEnabled
      ? Object.assign(
          {},
          this.formSubmitObject,
          this._getDatesIntervalForRealTime()
        )
      : Object.assign({}, this.formSubmitObject)

    return this._getData(this.state.operation, body)
      .then(response => this._onGetDataResponse(response))
      .then(() => {
        this.submitFormForRealtime = Object.assign({}, body)
        this.operationForRealtime = this.state.operation
      })
  }

  _loadRealTimeTimeSeries() {
    return this._getData(
      this.operationForRealtime,
      Object.assign(
        {},
        this.submitFormForRealtime,
        this._getDatesIntervalForRealTime()
      )
    ).then(response => this._onRealTimeGetDataResponse(response))
  }

  _onSubmitForm(ev) {
    ev.preventDefault()

    this.getTimeseriesRetries = GET_TIMESERIES_RETRIES
    this._clearInterval()

    this._loadTimeSeries().catch(reason => {
      this.setState({ loading: false, series: [] })

      console.error(`[ERROR]: Generic error: ${reason}`)
      message.error(
        this.props.intl.formatMessage(
          { id: "generic-error" },
          { status: reason }
        )
      )
    })
  }

  _getData(operation, body = {}) {
    const url = `${process.env.GATSBY_CONSUMER_API_URL}/data/${operation}?format=chart`
    const params = {
      method: "POST",
      headers: REQUEST_OPTIONS.headers,
      body: Object.assign({}, body),
    }

    return authFetch(url, params)
  }

  _onClickResetButton() {
    let labelFocus = this.state.labelFocus
    for (let k in labelFocus) {
      labelFocus[k] = "empty"
    }
    this.setState({ labelFocus })
    this.props.form.resetFields()
    this.formSubmitObject = {}

    this.setState({ series: [] })
    this._clearInterval()

    this._setSubmitButtonDisabledProperty()
  }

  _seriesHasData(series) {
    return Array.isArray(series) && series.some(({ data }) => data.length > 0)
  }

  _onRealTimeGetDataResponse(response) {
    response
      .json()
      .then(data => this._formatTimeseriesDataForChart(data))
      .then(series => {
        if (this._seriesHasData(series)) {
          this.setState({ series })
        }
      })
  }

  _onGetDataResponse(response) {
    const { status } = response

    if (status === 413) {
      response.json().then(response => {
        this._clearInterval()
        this.setState({ loading: false, series: [] })

        const warningMessage = ["snap", "avg"].includes(this.state.operation)
          ? "page-visualization-too-much-data"
          : "page-visualization-too-much-data-method-suggestion"

        message.warning(this.props.intl.formatMessage({ id: warningMessage }))
        console.error(`[ERROR]: Too many rows requested: ${response}`)
      })
    } else if (status === 409 || status >= 500) {
      if (this.getTimeseriesRetries > 0) {
        --this.getTimeseriesRetries
        setTimeout(() => this._loadTimeSeries(), 3000)
      } else {
        this.setState({ loading: false })
        this._clearInterval()

        const errorMessage =
          status === 409 ? `data-origin-error` : `generic-error`

        message.error(
          this.props.intl.formatMessage(
            { id: errorMessage },
            { status: status }
          )
        )
      }
    } else {
      response
        .json()
        .then(data => this._formatTimeseriesDataForChart(data))
        .then(series => {
          if (this._seriesHasData(series)) {
            this.setState({ loading: false, series }, () => {
              message.info(
                this.props.intl.formatMessage({
                  id: "page-visualization-data-loaded",
                })
              )
              this.getTimeseriesRetries = GET_TIMESERIES_RETRIES
              this._closeCollapse()
              this._getRealTimeDataPeriodically()
            })
          } else {
            message.info(
              this.props.intl.formatMessage({
                id: "page-visualization-no-data-to-render",
              })
            )
            this.setState({ loading: false, series })
          }
        })
        .catch(reason => {
          this._clearInterval()
          this.setState({ loading: false, series: [] }, () => {
            console.error(`[ERROR]: Generic error: ${reason}`)
            message.error(
              this.props.intl.formatMessage(
                { id: "generic-error" },
                { status: reason }
              )
            )
          })
        })
    }
  }

  _getRealTimeDataPeriodically(submitDisabled = this.state.submitDisabled) {
    if (
      !submitDisabled &&
      this.state.realTimeEnabled &&
      this._seriesHasData(this.state.series)
    ) {
      this._clearInterval()
      this._setInterval()
    }
  }

  _formatTimeseriesDataForChart(data = []) {
    return Object.entries(data).map(([name, data]) => ({ name, data }))
  }

  _closeCollapse() {
    this.setState({ collapseActiveKey: null })
  }

  _onSelectIngest(ingestId) {
    this.props.form.resetFields([
      "dictionaryFields",
      "dictionaryFieldValue",
      "metrics",
    ])

    this.setState({
      dictionaryFieldsDisabled: true,
      labelFocus: {
        ...this.state.labelFocus,
        ingests: "setted",
        dictionaryFields: "empty",
        dictionaryFieldValue: "empty",
        metrics: "empty",
      },
    })
    const { entity, dictionary = {} } =
      this.ingests.find(i => i.entity === ingestId) || {}
    const { fields = [] } = dictionary
    const fieldToUISelectField = field => ({
      value: field.name,
      text: `${field.name} (${field.type})`,
    })
    const dictionaryFields = fields.map(fieldToUISelectField)
    const metrics = fields
      .filter(f => f.property === "METRIC")
      .map(fieldToUISelectField)

    this.formSubmitObject = Object.assign({}, this.formSubmitObject, { entity })
    this.setState({
      dictionary: Array.isArray(dictionaryFields) ? dictionaryFields : [],
      metrics: Array.isArray(metrics) ? metrics : [],
      chartTitle: entity,
      dictionaryFieldsDisabled: false,
    })
    this._setSubmitButtonDisabledProperty()
  }

  _onChangePrefix(prefixes = []) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, prefixes: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      prefixes,
    })
  }

  _onSelectDictionaryField(id_name_field) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, dictionaryFields: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      id_name_field,
    })
    this._setSubmitButtonDisabledProperty()
  }

  _onChangeDictionaryFieldValues(ids = []) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, dictionaryFieldValue: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, { ids })
    this._setSubmitButtonDisabledProperty()
  }

  _onChangeMetric(metric_name_field) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, metrics: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      metric_name_field,
    })
    this._setSubmitButtonDisabledProperty()
  }

  _onDateRangeChange(dates = []) {
    //Both dates are present
    if (dates.length === 2) {
      let [start_date, end_date] = dates

      start_date = start_date.toDate().toISOString()
      end_date = end_date.toDate().toISOString()

      this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
        start_date,
        end_date,
      })
    } else {
      delete this.formSubmitObject.start_date
      delete this.formSubmitObject.end_date
    }

    this._setSubmitButtonDisabledProperty()
  }

  _onDateChange(date) {
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      date,
    })

    this._setSubmitButtonDisabledProperty()
  }

  _onChangeOffset(offset) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, offset: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      offset,
    })
    this._setSubmitButtonDisabledProperty()
  }

  _onSelectGranularity(granularity) {
    this.setState({
      labelFocus: { ...this.state.labelFocus, granularity: "setted" },
    })
    this.formSubmitObject = Object.assign({}, this.formSubmitObject, {
      granularity,
    })
    this._setSubmitButtonDisabledProperty()
  }

  _onSelectOperation(operation) {
    this.setState(
      {
        operation,
        labelFocus: {
          ...this.state.labelFocus,
          operation: "setted",
          granularity: ["average", "snap"].includes(operation)
            ? this.state.labelFocus.granularity
            : "empty",
          offset: ["last", "next"].includes(operation)
            ? this.state.labelFocus.offset
            : "empty",
        },
      },
      () => {
        this._setSubmitButtonDisabledProperty()
      }
    )
  }

  _onIntervalChange(interval) {
    this.realTimeInterval = interval
    this._setSubmitButtonDisabledProperty()
  }

  _setSubmitButtonDisabledProperty() {
    let submitDisabled = true
    const {
      entity,
      id_name_field,
      ids,
      metric_name_field,
      start_date,
      end_date,
      date,
      offset,
      granularity,
    } = this.formSubmitObject
    const _ids = ids && ids.length

    switch (this.state.operation) {
      case "last":
      case "next":
        submitDisabled = !(
          entity &&
          id_name_field &&
          _ids &&
          metric_name_field &&
          ((this.state.realTimeEnabled && this.realTimeInterval > 0) || date) &&
          offset
        )
        break
      case "average":
      case "snap":
        submitDisabled = !(entity &&
          id_name_field &&
          _ids &&
          metric_name_field &&
          ((this.state.realTimeEnabled && this.realTimeInterval > 0) ||
            (start_date && end_date)),
        granularity)
        break
      case "select":
        submitDisabled = !(
          entity &&
          id_name_field &&
          _ids &&
          metric_name_field &&
          ((this.state.realTimeEnabled && this.realTimeInterval > 0) ||
            (start_date && end_date))
        )
        break
      default:
        submitDisabled = true
    }

    if (submitDisabled) {
      this._clearInterval()
    }

    this.setState({ submitDisabled })

    return submitDisabled
  }

  _onPanelButtonClick() {
    const { collapseActiveKey } = this.state
    const value = collapseActiveKey === null ? "1" : null

    this.setState({ collapseActiveKey: value })
  }

  _onRealTimeSwitchChange(realTimeEnabled) {
    this.setState({ realTimeEnabled }, () => {
      if (!realTimeEnabled) {
        message.info(
          this.props.intl.formatMessage({
            id: "page-visualization-real-time-off",
          })
        )
        this._clearInterval()

        delete this.formSubmitObject.start_date
        delete this.formSubmitObject.end_date
        this._setSubmitButtonDisabledProperty()

        if (this._seriesHasData(this.state.series)) {
          // This isn't the best solution but we must force a re-render of the chart
          const series = this.state.series.slice()
          this.setState({ series })
        }
      } else {
        const disabled = this._setSubmitButtonDisabledProperty()
        this._getRealTimeDataPeriodically(disabled)

        message.info(
          this.props.intl.formatMessage({
            id: "page-visualization-real-time-on",
          })
        )
      }
    })
  }

  _onRefreshPeriodChange(period) {
    this.period = period * 1000 //s to ms

    this._getRealTimeDataPeriodically()
    message.info(
      this.props.intl.formatMessage({
        id: "page-visualization-refresh-period-updated",
      })
    )
  }

  _setInterval() {
    this.intervalTimer = setInterval(
      () => this._loadRealTimeTimeSeries(),
      this.period
    )
  }

  _clearInterval() {
    clearInterval(this.intervalTimer)
  }

  _renderLastNextOperationForm() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <>
        {this._renderDatePicker()}
        <Col offset={1} span={7}>
          <Form.Item>
            <FormControl variant="outlined" className="form-item" fullWidth>
              <InputLabel
                className={`knolar-input-label ${this.state.labelFocus.offset}`}
                htmlFor="offset"
              >
                {this.props.intl.formatMessage({
                  id: "page-visualization-form-offset-label",
                })}
              </InputLabel>
              {getFieldDecorator("offset", {
                rules: [
                  {
                    required: true,
                    message: _thatIntl.formatMessage({
                      id: "page-visualization-form-offset-required",
                    }),
                  },
                ],
              })(
                <InputNumber
                  className="knolar-select"
                  style={{ width: "100%" }}
                  min={1}
                  defaultValue={1}
                  onFocus={() => this._setLabelFocus("offset")}
                  onBlur={() => this._clearLabelFocus()}
                  onChange={this._onChangeOffset}
                />
              )}
            </FormControl>
            {this._renderErrorIcon("offset")}
          </Form.Item>
        </Col>
      </>
    )
  }

  _renderAverageOperationForm() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <>
        {this.state.realTimeEnabled
          ? this._renderRealTimeFromTimeSelector()
          : this._renderRangePicker()}
        <Col offset={1} span={7}>
          <Form.Item>
            <FormControl variant="outlined" className="form-item" fullWidth>
              <InputLabel
                className={`knolar-input-label ${this.state.labelFocus.granularity}`}
                htmlFor="granularity"
              >
                {this.props.intl.formatMessage({
                  id: "page-visualization-form-granularity-label",
                })}
              </InputLabel>
              {getFieldDecorator("granularity", {
                rules: [
                  {
                    required: true,
                    message: _thatIntl.formatMessage({
                      id: "page-visualization-form-granularity-required",
                    }),
                  },
                ],
              })(
                <Select
                  className="knolar-select"
                  name="granularity"
                  initalValue={GRANULARITIES[0].value}
                  showSearch={true}
                  onFocus={() => this._setLabelFocus("granularity")}
                  onBlur={() => this._clearLabelFocus()}
                  onSelect={this._onSelectGranularity}
                >
                  {GRANULARITIES.map(({ value, text }) => (
                    <Option key={value} value={value}>
                      {_thatIntl.formatMessage({ id: text })}
                    </Option>
                  ))}
                </Select>
              )}
            </FormControl>
            {this._renderErrorIcon("granularity")}
          </Form.Item>
        </Col>
      </>
    )
  }

  _renderRealTimeFromTimeSelector() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <Col offset={1} span={7}>
        <Form.Item>
          <FormControl variant="outlined" className="form-item" fullWidth>
            <InputLabel
              className={`knolar-input-label setted`}
              htmlFor="granularity"
            >
              {this.props.intl.formatMessage({
                id: "page-visualization-form-interval-label",
              })}
            </InputLabel>
            {getFieldDecorator("interval", {
              initialValue: INTERVALS[2].value,
              rules: [
                {
                  required: true,
                  message: _thatIntl.formatMessage({
                    id: "page-visualization-form-interval-required",
                  }),
                },
              ],
            })(
              <Select
                className="knolar-select"
                name="interval"
                onFocus={() => this._setLabelFocus("interval")}
                onBlur={() => this._clearLabelFocus()}
                style={{ width: "100%" }}
                onChange={this._onIntervalChange}
              >
                {INTERVALS.map(({ value, text }) => (
                  <Option key={value} value={value}>
                    {_thatIntl.formatMessage({ id: text })}
                  </Option>
                ))}
              </Select>
            )}
          </FormControl>
          {this._renderErrorIcon("interval")}
        </Form.Item>
      </Col>
    )
  }

  _renderRawOperationForm() {
    return this.state.realTimeEnabled
      ? this._renderRealTimeFromTimeSelector()
      : this._renderRangePicker()
  }

  _renderOperationForm() {
    switch (this.state.operation) {
      case "last":
      case "next":
        return this._renderLastNextOperationForm()
      case "average":
      case "snap":
        return this._renderAverageOperationForm()
      case "select":
        return this._renderRawOperationForm()
      default:
        return null
    }
  }

  _renderDatePicker() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <Col offset={1} span={7}>
        <Form.Item>
          <FormControl variant="outlined" className="form-item" fullWidth>
            {getFieldDecorator("date", {
              rules: [
                {
                  required: true,
                  message: _thatIntl.formatMessage({
                    id: "page-visualization-form-date-required",
                  }),
                },
              ],
            })(
              <DatePicker
                showTime
                onFocus={() => this._setLabelFocus("date")}
                onBlur={() => this._clearLabelFocus()}
                className="knolar-select"
                style={{ width: "100%" }}
                onChange={this._onDateChange}
                placeholder={_thatIntl.formatMessage({
                  id: "page-visualization-form-date-placeholder",
                })}
              />
            )}
          </FormControl>
          {this._renderErrorIcon("date")}
        </Form.Item>
      </Col>
    )
  }

  _renderRangePicker() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <Col offset={1} span={7}>
        <Form.Item>
          <FormControl variant="outlined" className="form-item" fullWidth>
            {getFieldDecorator("date-range", {
              rules: [
                {
                  required: true,
                  message: _thatIntl.formatMessage({
                    id: "page-visualization-form-date-range-required",
                  }),
                },
              ],
            })(
              <RangePicker
                className="knolar-select"
                style={{ width: "100%" }}
                allowClear={true}
                onFocus={() => this._setLabelFocus("date")}
                onBlur={() => this._clearLabelFocus()}
                ranges={{
                  Hoy: [moment().startOf("day"), moment().endOf("day")],
                  [_thatIntl.formatMessage({ id: "this-week" })]: [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  [_thatIntl.formatMessage({ id: "this-month" })]: [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  [_thatIntl.formatMessage({ id: "this-quarter" })]: [
                    moment().startOf("quarter"),
                    moment().endOf("quarter"),
                  ],
                  [_thatIntl.formatMessage({ id: "this-year" })]: [
                    moment().startOf("year"),
                    moment().endOf("year"),
                  ],
                }}
                showTime={true}
                format="YYYY/MM/DD HH:mm:ss"
                onChange={this._onDateRangeChange}
              />
            )}
          </FormControl>
          {this._renderErrorIcon("date-range")}
        </Form.Item>
      </Col>
    )
  }

  _renderChart() {
    return this.state.loading ? (
      <Spin size="large" />
    ) : (
      <TimeSeriesChart
        ref={this.chartRef}
        series={this.state.series}
        realTime={this.state.realTimeEnabled}
        title={this.state.chartTitle}
      />
    )
  }

  _setLabelFocus(key) {
    const labelFocus = this.state.labelFocus
    if (labelFocus[key] === "empty") {
      this.setState({
        labelFocus: {
          ...labelFocus,
          [key]: "focused",
        },
      })
    }
  }

  _clearLabelFocus() {
    const labelFocus = this.state.labelFocus
    for (let k in labelFocus) {
      if (labelFocus[k] === "focused") {
        labelFocus[k] = "empty"
      }
    }
    this.setState({ labelFocus })
  }

  _renderErrorIcon(key) {
    return this.props.form.getFieldError(key) ? (
      <img
        src={errorIcon}
        alt="error-icon"
        style={{
          zIndex: "10",
          background: "white",
          position: "absolute",
          bottom: "-3px",
          right: "10px",
        }}
      ></img>
    ) : (
      ""
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const _thatIntl = this.props.intl

    return (
      <Template selected={["visualization"]}>
        <CustomBreadcrumb
          crumbs={[_thatIntl.formatMessage({ id: "menu-timeseries" })]}
        />
        <Row>
          <Col className="knolar-intro">
            {_thatIntl.formatMessage({ id: "page-visualization-title" })}
          </Col>
        </Row>
        <Row className="knolar-content">
          <Collapse
            defaultActiveKey={this.collapseDefaultActiveKey}
            activeKey={this.state.collapseActiveKey}
            bordered={false}
            accordion={true}
            style={{ marginBottom: "15px" }}
          >
            <Panel
              key="1"
              header={
                <Row className="timeseries-form-header">
                  <Col span={7}>
                    <button
                      onClick={this._onPanelButtonClick}
                      className="timeseries-form-panel-btn"
                    >
                      {_thatIntl.formatMessage({
                        id: "page-visualization-config-title",
                      })}
                    </button>
                  </Col>

                  <Form layout="inline">
                    <Col
                      span={16}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Form.Item
                        style={{ fontSize: "0.85rem", fontWeight: "bold" }}
                        label={_thatIntl.formatMessage({
                          id: "page-visualization-rt-mode-header",
                        })}
                      >
                        <Switch
                          id="switch"
                          size="small"
                          onChange={this._onRealTimeSwitchChange}
                        />
                      </Form.Item>
                      <div
                        style={{
                          width: "1px",
                          background: "#e0e0e0",
                          height: "40px",
                          margin: "0 3rem 0 calc(3rem - 16px)",
                        }}
                      ></div>
                      <Form.Item
                        label={_thatIntl.formatMessage({
                          id: "page-visualization-refresh-period-label",
                        })}
                      >
                        <FormControl
                          variant="outlined"
                          className="form-item"
                          fullWidth
                        >
                          <InputLabel
                            className={"knolar-input-label setted"}
                            htmlFor="interval"
                            disabled={!this.state.realTimeEnabled}
                          >
                            {this.props.intl.formatMessage({
                              id: "page-visualization-time-interval",
                            })}
                          </InputLabel>
                          <Select
                            name="interval"
                            className="knolar-select"
                            onChange={this._onRefreshPeriodChange}
                            disabled={!this.state.realTimeEnabled}
                            defaultValue={REFRESH_INTERVALS[2].value}
                            style={{ width: "120px" }}
                          >
                            {REFRESH_INTERVALS.map(({ value, text }) => (
                              <option key={value} value={value}>
                                {_thatIntl.formatMessage({ id: text })}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Form.Item>
                    </Col>
                  </Form>
                </Row>
              }
            >
              <Form
                id="form"
                layout="horizontal"
                style={{ padding: "20px", borderTop: "1px solid #e0e0e0" }}
                onSubmit={this._onSubmitForm}
              >
                <Row style={{ margin: "30px 0" }}>
                  <Col span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.ingests}`}
                          htmlFor="ingests"
                          disabled={!this.state.ingests}
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-entity-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("ingests", {
                          rules: [
                            {
                              required: true,
                              message: _thatIntl.formatMessage({
                                id: "page-visualization-entity-required",
                              }),
                            },
                          ],
                        })(
                          <Select
                            name="ingest"
                            className="knolar-select"
                            loading={!this.state.ingests}
                            disabled={!this.state.ingests}
                            showSearch={true}
                            onFocus={() => this._setLabelFocus("ingests")}
                            onBlur={() => this._clearLabelFocus()}
                            onSelect={this._onSelectIngest}
                          >
                            {(this.state.ingests || []).map(a => (
                              <option key={a.value} value={a.value}>
                                {a.text}
                              </option>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                      {this._renderErrorIcon("ingests")}
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.prefixes}`}
                          htmlFor="prefixes"
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-prefixes-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("prefixes")(
                          <Select
                            name="prefixes"
                            mode="multiple"
                            className="knolar-select"
                            showSearch={true}
                            onFocus={() => this._setLabelFocus("prefixes")}
                            onBlur={() => this._clearLabelFocus()}
                            onChange={this._onChangePrefix}
                          >
                            {(this.state.prefixes || []).map(p => (
                              <Option key={p.value} value={p.value}>
                                {p.text}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormControl>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ margin: "30px 0" }}>
                  <Col span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.dictionaryFields}`}
                          htmlFor="dictionaryFields"
                          disabled={this.state.dictionaryFieldsDisabled}
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-dict-fields-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("dictionaryFields", {
                          rules: [
                            {
                              required: true,
                              message: _thatIntl.formatMessage({
                                id: "page-visualization-dict-fields-required",
                              }),
                            },
                          ],
                        })(
                          <Select
                            disabled={this.state.dictionaryFieldsDisabled}
                            name="dictionaryFields"
                            className="knolar-select"
                            onFocus={() =>
                              this._setLabelFocus("dictionaryFields")
                            }
                            onBlur={() => this._clearLabelFocus()}
                            showSearch={true}
                            onSelect={this._onSelectDictionaryField}
                          >
                            {(this.state.dictionary || []).map(
                              ({ value, text }) => (
                                <Option key={value} value={value}>
                                  {text}
                                </Option>
                              )
                            )}
                          </Select>
                        )}
                      </FormControl>
                      {this._renderErrorIcon("dictionaryFields")}
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.dictionaryFieldValue}`}
                          htmlFor="dictionaryFieldValue"
                          disabled={this.state.dictionaryFieldsDisabled}
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-dict-fields-values-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("dictionaryFieldValue", {
                          rules: [
                            {
                              required: true,
                              message: _thatIntl.formatMessage({
                                id:
                                  "page-visualization-dict-fields-values-required",
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="knolar-select"
                            onFocus={() =>
                              this._setLabelFocus("dictionaryFieldValue")
                            }
                            onBlur={() => this._clearLabelFocus()}
                            disabled={this.state.dictionaryFieldsDisabled}
                            mode="tags"
                            name="dictionaryFieldValue"
                            notFoundContent={null}
                            onChange={this._onChangeDictionaryFieldValues}
                          />
                        )}
                      </FormControl>
                      {this._renderErrorIcon("dictionaryFieldValue")}
                    </Form.Item>
                  </Col>
                  <Col offset={1} span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.metrics}`}
                          htmlFor="metrics"
                          disabled={this.state.dictionaryFieldsDisabled}
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-metrics-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("metrics", {
                          rules: [
                            {
                              required: true,
                              message: _thatIntl.formatMessage({
                                id: "page-visualization-metrics-required",
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="knolar-select"
                            onFocus={() => this._setLabelFocus("metrics")}
                            onBlur={() => this._clearLabelFocus()}
                            name="metrics"
                            showSearch={true}
                            mode="multiple"
                            disabled={this.state.dictionaryFieldsDisabled}
                            onChange={this._onChangeMetric}
                          >
                            {(this.state.metrics || []).map(
                              ({ value, text }) => (
                                <Option key={value} value={value}>
                                  {text}
                                </Option>
                              )
                            )}
                          </Select>
                        )}
                      </FormControl>
                      {this._renderErrorIcon("metrics")}
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ margin: "30px 0" }}>
                  <Col span={7}>
                    <Form.Item>
                      <FormControl
                        variant="outlined"
                        className="form-item"
                        fullWidth
                      >
                        <InputLabel
                          className={`knolar-input-label ${this.state.labelFocus.operation}`}
                          htmlFor="operation"
                        >
                          {this.props.intl.formatMessage({
                            id: "page-visualization-operation-label",
                          })}
                        </InputLabel>
                        {getFieldDecorator("operation", {
                          rules: [
                            {
                              required: true,
                              message: _thatIntl.formatMessage({
                                id: "page-visualization-operation-required",
                              }),
                            },
                          ],
                        })(
                          <Select
                            className="knolar-select"
                            name="operation"
                            onFocus={() => this._setLabelFocus("operation")}
                            onBlur={() => this._clearLabelFocus()}
                            showSearch={true}
                            onSelect={this._onSelectOperation}
                          >
                            {this.state.realTimeEnabled
                              ? OPERATIONS.filter(
                                  o => !["next", "last"].includes(o.value)
                                ).map(({ value, text }) => (
                                  <Option key={value} value={value}>
                                    {_thatIntl.formatMessage({ id: text })}
                                  </Option>
                                ))
                              : OPERATIONS.map(({ value, text }) => (
                                  <Option key={value} value={value}>
                                    {_thatIntl.formatMessage({ id: text })}
                                  </Option>
                                ))}
                          </Select>
                        )}
                      </FormControl>
                      {this._renderErrorIcon("operation")}
                    </Form.Item>
                  </Col>
                  {this._renderOperationForm()}
                </Row>
                <Row>
                  <Col
                    span={23}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button onClick={this._onClickResetButton}>
                      {_thatIntl.formatMessage({ id: "reset" })}
                    </Button>
                    <Button
                      style={{ marginLeft: "20px" }}
                      disabled={this.state.submitDisabled}
                      type="primary"
                      htmlType="submit"
                    >
                      {_thatIntl.formatMessage({ id: "visualize" })}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Collapse>
        </Row>
        <Row className="knolar-content timeseries-chart-container">
          {this._renderChart()}
        </Row>
      </Template>
    )
  }
}

export default injectIntl(
  Form.create({ name: "timeseries_visualization" })(withAuth(VisualizationPage))
)
