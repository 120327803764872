import React from "react"
import Loadable from "@loadable/component"
import moment from "moment-timezone"
import randomColor from "randomcolor"
import { Empty } from "antd"
import _ from "lodash"
import { injectIntl } from "gatsby-plugin-intl"

const MAX_TIME_SERIES_POINTS_COUNT = 15000

const DECIMAL_SEPARATOR = "."
const BREAKPOINTS_PAIRS = [
  {
    maxWidth: 800,
    chartWidth: 500,
  },
  {
    maxWidth: 1000,
    chartWidth: 600,
  },
  {
    maxWidth: 1200,
    chartWidth: 700,
  },
  {
    maxWidth: 2000,
    chartWidth: 900,
  },
  {
    maxWidth: 2400,
    chartWidth: 1400,
  },
]

class TimeSeriesChart extends React.Component {
  static _removeNDecimalsFromNumber(value, decimalsToLeave = 2) {
    let _value = value.toString()

    if (_value.indexOf(DECIMAL_SEPARATOR) !== -1) {
      const [integer, fractional] = _value.split(DECIMAL_SEPARATOR)

      return Number(
        [integer, fractional.slice(0, decimalsToLeave)].join(DECIMAL_SEPARATOR)
      )
    } else {
      return value
    }
  }

  static _generateRandomColors() {
    return randomColor({
      count: 20,
      luminosity: "dark",
      hue: "random",
    })
  }

  static _buildResponiveWidthBreakpoints() {
    return BREAKPOINTS_PAIRS.map(b => ({
      breakpoint: b.maxWidth,
      options: {
        chart: {
          width: b.chartWidth,
        },
      },
    }))
  }

  static _trimSeriesData(series) {
    const trimData = data =>
      Array.isArray(data) && data.length > MAX_TIME_SERIES_POINTS_COUNT
        ? data.slice(data.length - MAX_TIME_SERIES_POINTS_COUNT)
        : data

    return series.map(({ name, data }) => ({ name, data: trimData(data) }))
  }

  constructor(props) {
    super(props)

    this.state = {
      options: {
        lineTension: 0,
        stroke: {
          width: 1,
        },
        chart: {
          type: "line",
          animations: {
            enabled: false,
          },
          stacked: false,
        },
        plotOptions: {
          line: {
            curve: "smooth",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "full",
        },
        colors: TimeSeriesChart._generateRandomColors(),
        title: {
          text: this.props.title || "",
          align: "center",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: val => {
              if (typeof val === "number") {
                return TimeSeriesChart._removeNDecimalsFromNumber(val)
              } else {
                return val
              }
            },
          },
          title: {
            text: this.props.dataName,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: val => moment(val).format("HH:mm:ss - DD/MM/YYYY"),
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: val => val,
          },
        },
        responsive: TimeSeriesChart._buildResponiveWidthBreakpoints(),
      },
    }
  }

  _getOptions(realTime) {
    const baseOptions = Object.assign({}, this.state.options)
    let optionsToReturn = {}
    let optionsToOverride

    if (realTime) {
      optionsToOverride = {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
      }
    } else {
      optionsToOverride = {
        chart: {
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
      }
    }

    optionsToReturn = _.merge(optionsToReturn, baseOptions, optionsToOverride)

    return optionsToReturn
  }

  render() {
    const { series = [], realTime } = this.props
    const options = this._getOptions(realTime)

    return Array.isArray(series) &&
      series.length > 0 &&
      series.some(s => s.data.length > 0) ? (
      <Chart
        series={TimeSeriesChart._trimSeriesData(series)}
        width={1200}
        options={options}
        type="area"
      />
    ) : (
      <Empty description={this.props.intl.formatMessage({ id: "no-data" })} />
    )
  }
}

export default injectIntl(TimeSeriesChart)

const Chart = Loadable(() => import("react-apexcharts"))
